import { ITabs } from '../types';

export const tabs: ITabs[] = [
  {
    label: 'Step 1/5',
    value: 'step1',
    subHeading: 'Overview',
    isSecondary: true
  },
  {
    label: 'Step 2/5',
    value: 'step2',
    subHeading: 'Terms & Conditions',
    isSecondary: true
  },
  {
    label: 'Step 3/5',
    value: 'step3',
    subHeading: 'Create your company',
    isSecondary: true
  },
  // {
  //   label: 'Step 4/6',
  //   value: 'step4',
  //   subHeading: 'Vehicle subscription',
  //   isSecondary: false
  // },
  {
    label: 'Step 4/5',
    value: 'step4',
    subHeading: 'Vehicle information',
    isSecondary: false
  },
  {
    label: 'Step 5/5',
    value: 'step5',
    subHeading: 'Thank you',
    isSecondary: true
  }
  // {
  //   label: 'Step 5/6',
  //   value: 'step5',
  //   subHeading: 'Billing confirmation',
  //   isSecondary: false
  // },
];
