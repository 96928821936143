export interface ICategoryData {
  category: string;
  subcategory: string;
  subcategory_name: string;
  amount: string;
  instance_count: string;
  score: number;
  isClickable?: boolean;
  veh_id?: string[];
  screen?: string;
  trip_ids?: number[];
}

export interface ICategoryApiResponse {
  isSuccess: boolean;
  data: ICategoryData[];
}

export const categoryAmountsToHide = ['Partial GPS', 'Missing GPS', 'OEM Issue'];
export const savingsCategory = [
  {
    label: 'MAXIMUMTOLLS',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'DUPLICATE',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'MISCLASSIFICATIONS',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'LICENSEPLATETOLLS',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'POTENTIAL_MISUSE',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'DELAYED_POSTINGS',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'TOLL_TRANSPONDER_FIXES',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'LICENSE_PLATE_FIXES',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'NOVALUETRIPS',
    isClickable: true,
    screen: 'trips'
  },
  {
    label: 'POTENTIALVIOLATION',
    isClickable: true,
    screen: 'transactions'
  }
];
