/* eslint-disable */
import { UnionDataInterface } from '@common/types/data';
import { ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import Status from '@common/ui/Status';
import { formatFieldData } from '@common/services/format';
import Highlighter from 'react-highlight-words';
import { SignalHigh, SignalLow, SignalMedium } from '@assets/icons';
import { DataColumnProps } from '../types';
import styles from '../column.module.scss';
import { formatTimeSavedDate } from '@common/services/format/field-data';

export enum EGPSReliability {
  high = 'HIGH',
  medium = 'MEDIUM',
  low = 'LOW'
}

const DataField = ({
  tableName,
  heading,
  field,
  value,
  rowData
}: {
  tableName: ETableName;
  heading: string;
  field?: string;
  value: DataColumnProps['value'];
  rowData?: any;
}) => {
  const search = useAppSelector(state => state.search[tableName]);
  const isMetric: boolean = useAppSelector((state: any) => state.auth.isMetric);
  const timeZone: string = useAppSelector((state: any) => state.auth.timeZoneId);
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);

  // ? modify field data
  let modValue;
  if ([ETableName.TRIPS].includes(tableName) && heading === 'Time Saved') {
    let time = Number(value);
    modValue = formatTimeSavedDate(time);
  } else {
    modValue = formatFieldData(
      value,
      heading,
      tableName,
      isMetric,
      timeZone,
      field as keyof UnionDataInterface,
      rowData,
      isActualTollTab
    );
  }
  const searchableColumn =
    (tableName === ETableName.TRANSACTIONS &&
      ['tollAgency', 'plaza'].includes(_.camelCase(heading))) ||
    (tableName === ETableName.FLEET &&
      ['name', 'licensePlate', 'licensePlateState', 'licensePlateNumber', 'vin'].includes(
        _.camelCase(heading)
      )) ||
    (tableName === ETableName.TRIPS &&
      ['vehicleName', 'licensePlate', 'tripId', 'licensePlateState', 'licensePlateNumber'].includes(
        _.camelCase(heading)
      )) ||
    (tableName === ETableName.USERS && ['emailAddress', 'name'].includes(_.camelCase(heading))) ||
    (tableName === ETableName.TOLLDOCUMENTS &&
      ['tollDocumentName'].includes(_.camelCase(heading))) ||
    (tableName === ETableName.CUSTOMERS && ['companyName'].includes(_.camelCase(heading)));

  const fetchGpsIcon = (signal: string) => {
    const casedSignal = signal.toUpperCase();
    switch (casedSignal) {
      case EGPSReliability.high:
        return <SignalHigh className={styles['signal-icon']} />;
      case EGPSReliability.medium:
        return <SignalMedium className={styles['signal-icon']} />;
      case EGPSReliability.low:
        return <SignalLow className={styles['signal-icon']} />;
      default:
        return <SignalLow className={styles['signal-icon']} />;
    }
  };

  const getColor = (modValue?: any, heading?: any) => {
    if (tableName === ETableName.TRIPS && heading && heading.toLowerCase().startsWith('toll cost /')) {
      const timeSaved = modValue;
      if (timeSaved < 0) {
        return styles['data-red'];
      } else if (timeSaved > 0) {
        return styles['data-green'];
      }
    }
    return '';
  };
  const getTollCost = (value: any) => {
    if ([ETableName.TRIPS].includes(tableName) && heading.toLowerCase().startsWith('toll cost /')) {
      if (value) {
        const numericValue = parseFloat(value);
        const formattedValue = numericValue.toFixed(2);
        const unit = heading.toLowerCase().endsWith("minute") ? "min" : "mile";
        return numericValue < 0
          ? `-$${Math.abs(Number(formattedValue))}/${unit}`
          : `$${formattedValue}/${unit}`;
      } else {
        return value;
      }
    }
  };

  return (
    <>
      {modValue && modValue !== 'null' && modValue !== 'None' && modValue !== 'none' ? (
        <p
          className={`${styles.container} ${styles.datacontainer} ${
            styles[_.kebabCase(`${tableName} ${heading}`)]
          } `}
        >
          {heading && heading.toLowerCase() === 'gps reliability' ? (
            fetchGpsIcon(modValue.toString())
          ) : (
            <>
              {heading.toLowerCase() !== 'status' ? (
                <span
                  className={`${styles.data} ${getColor(modValue, heading)}`}
                  title={modValue as string}
                >
                  {searchableColumn ? (
                    <Highlighter
                      highlightClassName={styles['search-highlight']}
                      searchWords={search.by ? [`${search.by}`.trim()] : []}
                      autoEscape
                      textToHighlight={modValue.toString() || ''}
                    />
                  ) : [ETableName.TRIPS].includes(tableName) && heading.toLowerCase().startsWith('toll cost /') ? (
                    getTollCost(modValue)
                  ) : (
                    modValue.toString() || ''
                  )}
                </span>
              ) : (
                <Status
                  status={(modValue as string)?.toLowerCase() || ''}
                  uppercase={
                    !['transactions', 'toll_documents', 'toll_document'].includes(tableName)
                  }
                >
                  {searchableColumn ? (
                    <Highlighter
                      highlightClassName={styles['search-highlight']}
                      searchWords={search.by ? [`${search.by}`.trim()] : []}
                      autoEscape
                      textToHighlight={modValue.toString() || ''}
                    />
                  ) : (
                    modValue.toString() || ''
                  )}
                </Status>
              )}
            </>
          )}
        </p>
      ) : (
        <p
          className={`${styles.container} ${styles[_.kebabCase(`${tableName} ${heading}`)]} ${
            styles['not-found']
          }`}
        >
          {heading === 'amount' || heading === 'total amount' ? (modValue === 0 ? '$0.00' : '-') : '-'}
        </p>
      )}
    </>
  );
};

export default DataField;
